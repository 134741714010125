import React from 'react';

function ProjectsSection() {
  return (
    <div className="site-section">
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-4 mx-auto">
            <h2 className="line-bottom text-center">Our Projects</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="project-item">
              <img src="images/cgs_5.jpg" alt="Image" className="img-fluid" />
              <div className="project-item-overlay">
                <a className="category" href="#">Cleaning</a>
                <span className="plus">
                  <span className="icon-plus"></span>
                </span>
                <a href="#" className="project-title"><span>Our cleaning services have consistently exceeded expectations, leaving our clients delighted with the results. We have transformed messy and disorganized spaces into sparkling and inviting environments. With our meticulous attention to detail and skilled team, we have successfully tackled a wide range of cleaning projects, ensuring every nook and cranny is spotless. Trust us to bring cleanliness and freshness to your space, creating a healthier and more enjoyable atmosphere.</span></a>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="project-item">
              <img src="images/cgs_1.jpg" alt="Image" className="img-fluid" />
              <div className="project-item-overlay">
                <a className="category" href="#">Logistics</a>
                <span className="plus">
                  <span className="icon-plus"></span>
                </span>
                <a href="#" className="project-title"><span>Efficient logistics solutions for seamless operations. Streamlined supply chain management, optimized transportation, and reliable deliveries. Trust us for hassle-free logistics services.</span></a>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="project-item">
              <img src="images/cgs_2.jpg" alt="Image" className="img-fluid" />
              <div className="project-item-overlay">
                <a className="category" href="#">Electrical</a>
                <span className="plus">
                  <span className="icon-plus"></span>
                </span>
                <a href="#" className="project-title"><span>Power up your space with our professional electrical services. We specialize in installations, repairs, and maintenance to ensure your electrical systems are safe and efficient. Trust our experienced team for all your electrical needs.</span></a>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="project-item">
              <img src="images/cgs_3.jpg" alt="Image" className="img-fluid" />
              <div className="project-item-overlay">
                <a className="category" href="#">General Repair and Maintenance</a>
                <span className="plus">
                  <span className="icon-plus"></span>
                </span>
                <a href="#" className="project-title"><span>Reliable solutions for all your general repair and maintenance needs. Our skilled team is equipped to handle a wide range of tasks, from fixing minor issues to conducting thorough maintenance checks. Count on us to keep your space in top shape.</span></a>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="project-item">
              <img src="images/cgs_4.jpg" alt="Image" className="img-fluid" />
              <div className="project-item-overlay">
                <a className="category" href="#">House and Office Cleaning</a>
                <span className="plus">
                  <span className="icon-plus"></span>
                </span>
                <a href="#" className="project-title"><span>We have brought back beauty to homes and offices across the nation</span></a>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="project-item">
              <img src="images/cgs_6.jpg" alt="Image" className="img-fluid" />
              <div className="project-item-overlay">
                <a className="category" href="#">Property Search</a>
                <span className="plus">
                  <span className="icon-plus"></span>
                </span>
                <a href="#" className="project-title"><span>Simplify your property search with our comprehensive services. We assist you in finding the perfect property that meets your needs and preferences. Trust our expertise and extensive listings to make your property search a breeze.</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsSection;