import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Home, Bookmark, User } from "react-feather";

function Nav() {
  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 640;

  return (
    // <Styles.Wrapper>
    //   <CSSReset />
    <div style={{
      display: "flex",
      backgroundColor: "#eeeeee",
      margin: 0,
      position: "relative",
      zIndex: 1
    }}>
      {isMobile ? (
        <MobileNavbar.Wrapper>
          <MobileNavbar.Items>
            <MobileNavbar.Item onClick={() => {
              window.location.href = '/';
            }}>
              <MobileNavbar.Icon>
                <Home size={16} />
              </MobileNavbar.Icon>
              Home
            </MobileNavbar.Item>
            <MobileNavbar.Item onClick={() => {
              window.location.href = '/post-jobs';
            }}>
              <MobileNavbar.Icon>
                <Bookmark size={16} />
              </MobileNavbar.Icon>
              Jobs
            </MobileNavbar.Item>
            <MobileNavbar.Item onClick={() => {
              window.location.href = 'https://cghomesltd.com/shop/';
            }}>
              <MobileNavbar.Icon>
                <User size={16} />
              </MobileNavbar.Icon>
              Shop
            </MobileNavbar.Item>
          </MobileNavbar.Items>
        </MobileNavbar.Wrapper>
      ) : (
        <Navbar.Wrapper>
          <Navbar.Logo onClick={() => {
            window.location.href = '/';
          }}>
            <img src="images/PNG/CGS/CGS-Black.png" alt="Logo" style={{ width: "150px", height: "auto" }} />
          </Navbar.Logo>



          <Navbar.Items>
            <Navbar.Item onClick={() => {
              window.location.href = '/';
            }}>Home</Navbar.Item>
            <Navbar.Item onClick={() => {
              window.location.href = 'https://cghomesltd.com/shop/';
            }}>Shop</Navbar.Item>
            <Navbar.Item onClick={() => {
              window.location.href = 'https://cghomesltd.com/';
            }}>Real Estate</Navbar.Item>
            <button className="btn btn-primary px-7" onClick={() => {
              window.location.href = '/post-jobs';
            }}>Post Jobs</button>
          </Navbar.Items>
        </Navbar.Wrapper>
      )}
    </div>
    // </Styles.Wrapper>
  );
}

// const Styles = {
//   Wrapper: styled.main`
//     display: flex;
//     background-color: #eeeeee;
//     margin: 0;
//     position: relative;
//     z-index: 999;
//   `,
// };

const Navbar = {
  Wrapper: styled.nav`
    flex: 1;
    align-self: flex-start;
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
  `,
  Logo: styled.h1`
    display: flex; /* Added */
    align-items: center; /* Added */
    padding: 0.5rem 1rem;
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;
  `,
  Item: styled.li`
    padding: 0 1rem;
    cursor: pointer;
  `,
};

const MobileNavbar = {
  Wrapper: styled(Navbar.Wrapper)`
    position: fixed;
    width: 100vw;
    bottom: 0;
  `,
  Items: styled(Navbar.Items)`
    flex: 1;
    padding: 0 2rem;
    justify-content: space-around;
  `,
  Item: styled(Navbar.Item)`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
  `,
  Logo: styled.h1`
  display: flex; /* Added */
  align-items: center; /* Added */
  padding: 0.5rem 1rem;
`,
  Icon: styled.span``,
};

const CSSReset = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
`;

export default Nav;
