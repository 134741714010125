import React from 'react';

function TestimonialsSection() {
  return (
    <div className="site-section bg-light">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-4 mx-auto">
            <h2 className="line-bottom text-center">Testimonials</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="testimonial-3">
              <blockquote>They listen and respond efficiently to client's need. Highly recommended</blockquote>
              <div className="vcard d-flex align-items-center">
                <div className="img-wrap mr-3">
                  <img src="https://cghomesltd.com/wp-content/uploads/2023/03/WhatsApp-Image-2023-03-01-at-8.20.15-PM.jpeg" alt="Image" className="img-fluid" />
                </div>
                <div>
                  <span className="d-block">Andrew Eromosele</span>
                  <span className="position">CEO Xperia Ttavels</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="testimonial-3">
              <blockquote>They are friendly, professional and the most diligent property management I have ever dealt with.</blockquote>
              <div className="vcard d-flex align-items-center">
                <div className="img-wrap mr-3">
                  <img src="https://cghomesltd.com/wp-content/uploads/2023/03/WhatsApp-Image-2023-03-01-at-8.20.25-PM.jpeg" alt="Image" className="img-fluid" />
                </div>
                <div>
                  <span className="d-block">Eniola Joseph</span>
                  <span className="position">MD Shop June</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialsSection;