import React, { useState, useEffect } from 'react';

function CgSection() {
    const [backgroundImage, setBackgroundImage] = useState('images/cgs.jpg');
    const [displayText, setDisplayText] = useState('');

    useEffect(() => {
        const timer = setInterval(() => {
            // Generate a random number between 1 and 3
            const randomNum = Math.floor(Math.random() * 3) + 1;
            // Set the background image based on the random number
            setBackgroundImage(`images/hero_${randomNum}.jpg`);

            // Set the display text based on the random number
            const statements = [
                "Discover your dream home with our exclusive real estate deals.",
                "Unlock amazing real estate opportunities just a click away.",
                "Find the perfect property that fits your lifestyle and budget."
            ];
            setDisplayText(statements[randomNum - 1]);
        }, 5000); // Change image and text every 5 seconds

        return () => clearInterval(timer); // Cleanup the timer on component unmount
    }, []);

    return (
        // <div className="ftco-blocks-cover-1">
        <div className="ftco-cover-1 overlay" style={{ height: "50vh", backgroundImage: `url(${backgroundImage})`, position: "relative" }}>
            <div className="hero-overlay"></div>
            <div className="container">
                <div className="row justify-content-center align-items-center" style={{ height: "100%", }}>
                    <div className="col-lg-8 text-center">
                        <h1 className="display-4 text-white mb-4" style={{ marginTop: "20%" }}>{displayText}</h1>
                        <p className="lead text-white">Find your perfect real estate deal now!</p>
                        <button className="btn btn-primary btn-lg" style={{ marginTop: "10px" }}>Check it out</button>
                    </div>
                </div>
            </div>
        {/* </div> */}
        </div>
    );
}

export default CgSection;
