import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaPinterest } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faUser, faClock, faStar } from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-modal';
import CompanyInfo from '../components/company-info';
import TestimonialsSection from '../components/testimonial';
import ProjectsSection from '../components/projects';
import ServiceSection from '../components/services';
import Nav from '../components/navbar';
import { useTransition, animated } from 'react-spring';
import WhatWeDoSection from '../components/what-we-do';


function Home() {


    const [backgroundImage, setBackgroundImage] = useState('images/hero_1.jpg');
    const [displayText, setDisplayText] = useState('');
    const [formClosed, setFormClosed] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [service, setService] = useState('');
    const [location, setLocation] = useState('');
    const [date, setDate] = useState('');
    const [budgetMin, setBudgetMin] = useState('');
    const [budgetMax, setBudgetMax] = useState('');
    const [description, setDescription] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [contactMethod, setContactMethod] = useState('Phone');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedResult, setSelectedResult] = useState(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [showLocationField, setShowLocationField] = useState(false); // New state variable
    // const sectionAnimation = useTransition({
    //     opacity: 1,
    //     from: { opacity: 0 },
    //     delay: 500,
    //   });


    useEffect(() => {
        const fetchSearchResults = async () => {
            try {
                const response = await axios.get(
                    // `http://localhost:3001/api/v1/service/services/${searchTerm}`
                    `https://handyman-j1j1.onrender.com/api/v1/service/services/${searchTerm}`
                );
                setSearchResults(response.data.data);
                setDropdownVisible(true);
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        };

        if (searchTerm.trim() !== '') {
            fetchSearchResults();
        } else {
            setSearchResults([]);
        }
    }, [searchTerm]);

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        setSelectedResult(null);
        setDropdownVisible(false);
    };

    const handleSelectResult = (result) => {
        setSelectedResult(result);
        setSearchTerm(result.name);
        setDropdownVisible(false);
        setShowLocationField(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Submit the form data to the server
        const formData = {
            name,
            email,
            phone,
            services: [
                {
                    service: selectedResult._id, // Use the ID of the selected service
                    description,
                    location,
                    date,
                    contactMethod,
                    budget: {
                        min: budgetMin,
                        max: budgetMax,
                    },
                },
            ],
        };

        try {
            const response = await axios.post(
                // 'http://localhost:3001/api/v1/service/order/create',
                'https://handyman-j1j1.onrender.com/api/v1/service/order/create',
                formData
            );
            console.log(response.data);
            // Clear the form fields
            setEmail('');
            setPhone('');
            setService('');
            setDescription('');
            setLocation('');
            setDate('');
            setBudgetMin('');
            setBudgetMax('');
            // Open the success modal
            setSuccessModalOpen(true);
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    const closeSuccessModal = () => {
        setSuccessModalOpen(false);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            // Generate a random number between 1 and 3
            const randomNum = Math.floor(Math.random() * 3) + 1;
            // Set the background image based on the random number
            setBackgroundImage(`images/hero_${randomNum}.jpg`);

            // Set the display text based on the random number
            const statements = [
                "We have the best service providers for anything you need.",
                "Clean your home, fix your furniture's, Install CCTV, Tiles, POP, windows, painting",
                "Unlock the full potential of your living space with our expert services.",
                // "Whether you want to clean your home, fix your furniture's, Install CCTV,\\n Tiles, POP, windows, painting, anything you want to do in your home we \\n have right professionals artisans that will handle your Job with ease"

            ];
            setDisplayText(statements[randomNum - 1]);
        }, 3000); // Change image and text every 5 seconds

        return () => clearInterval(timer); // Cleanup the timer on component unmount
    }, []);


    return (
        <div data-spy="scroll" data-target=".site-navbar-target" data-offset="300">
            <Nav />



            <div className="site-wrap" id="home-section">


               

                <div className="ftco-blocks-cover-1">
                    <div className="ftco-cover-1 overlay" style={{ backgroundImage: `url(${backgroundImage})` }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <h1 className="line-bottom mt-6" style={{ width: "80%" }}>{displayText}</h1>
                                    {/* <form> <div class="form-group"> */}
                                  <button onClick={() => {window.location.href = '/post-jobs'}} type="submit" value="" class="btn btn-primary  px-5">Request Service </button>

                                        {/* <input type="submit" value="Request Service" class="btn btn-primary px-5" /> */}
                                    {/* </div></form> */}
                                </div>
                                <div className="col-lg-5 ml-auto"></div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <animated.div className="site-section" style={{ position: "relative" }}> */}
                <div className="site-section" style={{ position: " relative" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 pr-md-5 mr-auto p-3" style={{ backgroundColor: "black" }}>
                                <h4>About Us</h4>
                                <h2 className="line-bottom" style={{ color: "white" }}>We have the best service providers for anything you need.</h2>
                                <p style={{ color: "white" }}>CGHomes is here to revolutionize your home maintenance experience Say Good bye to unreliable artisans and hello to a trusted company that takes responsibility for every job. With CGHOMES, you’ll receive excellent value for your payment, avoiding the hassle of chasing vanishing artisans. Book your appointment now and enjoy the benefits of a professional and punctual service.</p>
                                {/* <form> <div class="form-group"> */}
                                  <button onClick={() => {window.location.href = '/post-jobs'}} type="submit" value="" class="btn btn-primary  px-5">Request Service </button>
                                {/* </div> */}
                                {/* </form> */}
                                <img src='images/PNG/CGS/CGS-White.png' style={{ marginRight: "0px", width: "50px", height: "50px", float: "right" }} />
                            </div>
                            {/* </div>
                            <div className="row"> */}
                            <div className="col-md-5 p-3" style={{ display: "flex", justifyContent: "space-evenly", flexWrap: "wrap", width: "100%" }}>
                                <div className="stat-item" style={{ backgroundColor: "", height: "150px", padding: "20px", marginBottom: "20px", flex: "0 0 calc(50% - 20px)" }}>
                                    <FontAwesomeIcon icon={faCheckCircle} className="stat-icon" size="3x" />
                                    <h3 style={{ fontSize: "24px" }}>80+</h3>
                                    <p>Successfully Completed Projects</p>
                                </div>
                                <div className="stat-item" style={{ backgroundColor: "", height: "150px", padding: "20px", marginBottom: "20px", flex: "0 0 calc(50% - 20px)" }}>
                                    <FontAwesomeIcon icon={faUser} className="stat-icon" size="3x" />
                                    <h3 style={{ fontSize: "24px" }}>35+</h3>
                                    <p>Staff Trained</p>
                                </div>
                                <div className="stat-item" style={{ backgroundColor: "", height: "150px", padding: "20px", marginBottom: "20px", flex: "0 0 calc(50% - 20px)" }}>
                                    <FontAwesomeIcon icon={faClock} className="stat-icon" size="3x" />
                                    <h3 style={{ fontSize: "24px" }}>5+</h3>
                                    <p>Years of Experience</p>
                                </div>
                                <div className="stat-item" style={{ backgroundColor: "", height: "150px", padding: "20px", marginBottom: "20px", flex: "0 0 calc(50% - 20px)" }}>
                                    <FontAwesomeIcon icon={faStar} className="stat-icon" size="3x" />
                                    <h3 style={{ fontSize: "24px" }}>15+</h3>
                                    <p>Recommendations from Clients</p>
                                </div>
                            </div>

                        </div>
                        {/* <div className="col-md-6">
                                <div className="quick-contact-form bg-white">
                                    <h2>Request for service now !</h2>
                                   

                                </div>
                            </div> */}
                    </div>
                </div>
                {/* </animated.div> */}
                {/* </div> */}
                <ServiceSection />
                <CompanyInfo />
                <WhatWeDoSection />
                <ProjectsSection />
                <TestimonialsSection />
                
                <footer className="site-footer footer-section mb-0" style={{height : ""}}>
                    {/* <div className=""> */}
                    <div className="section-padd">
                        <div className="container">
                            <div className="top-footer">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="footer-logo">
                                            <a href="/">
                                                <img src="images/PNG/CGS/CGS-White.png" alt="Logo" style={{ width: "150px", height: "auto" }} />
                                            </a>
                                        </div>
                                        <div className="social-icons">
                                            <ul style={{
                                                listStyleType: "none"
                                            }}>
                                                <br />
                                                <br />
                                                <li>
                                                    <p>Greenwood park estate, opposite corona school, G. R. A. Second gate bus stop, lekki-epe express way, Lagos</p>
                                                </li>
                                                <li>
                                                    <p>+2349038023858</p>
                                                </li>
                                                <li>
                                                    <p>info@cghomesltd.com</p>
                                                </li>
                                                <li>
                                                    <a href="javascript:;">
                                                        <i className="icon-google-plus"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;">
                                                        <i className="icon-pinterest"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="social-icons" style={{ color: 'white', display: 'inline-block' }}>
                                            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                                                <li style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    <a href="https://www.facebook.com/cghomesltd/">
                                                        <FaFacebook />
                                                    </a>
                                                </li>
                                                <li style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    <a href="#">
                                                        <FaTwitter />
                                                    </a>
                                                </li>
                                                <li style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    <a href="https://instagram.com/cghomesltd">
                                                        <FaInstagram />
                                                    </a>
                                                </li>
                                                <li style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    <a href="https://www.youtube.com/@cghomeslimited">
                                                        <FaYoutube />
                                                    </a>
                                                </li>
                                                {/* <li style={{ display: 'inline-block' }}>
                                                    <a href="#">
                                                        <FaPinterest />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="footer-links">
                                            <ul style={{
                                                listStyleType: "none"
                                            }}>
                                                <li>
                                                    <strong className="text-primary">Customers</strong>
                                                </li>
                                                <li>
                                                    <a href="/post-jobs">Post a job</a>
                                                </li>
                                                <li>
                                                    <a href="/post-jobs">Explore Services</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="footer-links">
                                            <ul style={{
                                                listStyleType: "none"
                                            }}>
                                                <li>
                                                    <strong className="text-primary">Real Estate Service</strong>
                                                </li>
                                                <li>
                                                    <a href="https://cghomesltd.com">See the list of available properties</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="footer-links">
                                            <ul style={{
                                                listStyleType: "none"
                                            }}>
                                                <li>
                                                    <strong className="text-primary"> CG Shop</strong>
                                                </li>
                                                <li>
                                                    <a href="https://cghomesltd.com/shop/">Shop For Your Interior Item</a>
                                                </li>
                                                <li>
                                                    <a href="https://cghomesltd.com/auctions/">Visit our auction page</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-copyrights">
                                <div className="section-padding-sm clearfix">
                                    <div className="float-left">
                                        <p>Copyright © 2023 CG Homes.</p>
                                    </div>
                                    <div className="float-right terms-links">
                                        <ul style={{
                                                listStyleType: "none"
                                            }}>
                                            <li>
                                                <a href="/explore#">Terms &amp; Conditions</a>
                                            </li>
                                            <li>
                                                <a href="/explore#">Privacy Policy</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </footer>
            </div >
        </div >
    )
}

export default Home;