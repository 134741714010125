import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Nav from '../components/navbar';
import { FaFacebook, FaTwitter, FaInstagram, FaGooglePlus, FaPinterest } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faUser, faClock, faStar } from '@fortawesome/free-solid-svg-icons';
import PostJobForm from '../components/search-service';
import StateSelect from '../components/selectState';

function PostJob() {


    const [backgroundImage, setBackgroundImage] = useState('images/hero_1.jpg');
    const [displayText, setDisplayText] = useState('');
    const [formClosed, setFormClosed] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [service, setService] = useState('');
    const [location, setLocation] = useState('');
    const [date, setDate] = useState('');
    const [minBudget, setMinBudget] = useState('');
    const [maxBudget, setMaxBudget] = useState('');
    const [description, setDescription] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [contactMethod, setContactMethod] = useState('Phone');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedResult, setSelectedResult] = useState(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [state, setState] = useState('');
    const [selectedService, setSelectedService] = useState(null);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [showLocationField, setShowLocationField] = useState(false); // New state variable


    useEffect(() => {
        const fetchSearchResults = async () => {
            try {
                const response = await axios.get(
                    // `http://localhost:3001/api/v1/service/services/${searchTerm}`
                    `https://handyman-j1j1.onrender.com/api/v1/service/services/${searchTerm}`
                );
                setSearchResults(response.data.data);
                setDropdownVisible(true);
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        };

        if (searchTerm.trim() !== '') {
            fetchSearchResults();
        } else {
            setSearchResults([]);
        }
    }, [searchTerm]);

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        setSelectedResult(null);
        setDropdownVisible(false);
    };

    const handleSelectResult = (result) => {
        setSelectedResult(result);
        setSearchTerm(result.name);
        setDropdownVisible(false);
        setShowLocationField(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
          // Determine if the service is new or existing
          const isNewService = !selectedService || !selectedService._id;
      
          // Prepare the form data
          const formData = {
            name,
            email,
            phone,
            location,
            date,
            minBudget,
            maxBudget,
            description,
            pref: contactMethod,
            service: isNewService ? null : (selectedService._id || null),
            serviceName: isNewService ? searchTerm : (selectedService ? selectedService.name : ''),
            services: [
              {
                isNewService,
                name: isNewService ? searchTerm : (selectedService ? selectedService.name : ''),
                description: isNewService ? description : (selectedService ? selectedService.description : ''),
                location: isNewService ? location : (selectedService ? selectedService.location : ''),
                date: isNewService ? date : (selectedService ? selectedService.date : ''),
                budget: {
                  min: isNewService ? minBudget : (selectedService ? selectedService.budget.min : ''),
                  max: isNewService ? maxBudget : (selectedService ? selectedService.budget.max : ''),
                },
              },
            ],
          };
          

        // Determine if the service is new or existing
        // const isNewService = !selectedService || !selectedService._id;
        // // Submit the form data to the server
        // const formData = {
        //     name,
        //     email,
        //     phone,
        //     service: isNewService ? null : selectedService,
        //     location,
        //     date,
        //     minBudget,
        //     maxBudget,
        //     description,
        //     pref: contactMethod,
        //   };

        try {
            const response = await axios.post(
                // 'http://localhost:3001/api/v1/service/order/create',
                'https://handyman-j1j1.onrender.com/api/v1/service/order/create',
                formData
            );
            console.log(response.data);
            // Clear the form fields
            setName('');
            setEmail('');
            setPhone('');
            setSelectedService(null);
            setDescription('');
            setLocation('');
            setDate('');
            setMinBudget('');
            setMaxBudget('');
      
            // Display success message
            setSuccessMessage('Form submitted successfully! Our team will reach out shortly');
            setErrorMessage('');
          } catch (error) {
            console.error('Error submitting the form:', error);
            // Display error message
            setSuccessMessage('');
            setErrorMessage('Form submission failed. Please try again.');
          }
    };

    const closeSuccessModal = () => {
        setSuccessModalOpen(false);
    };

    const handleStateChange = (e) => {
        const selectedState = e.target.value;
        setState(selectedState);
        handleInputChange(selectedState);
    };

    const nigerianStates = [
        'Abia',
        'Adamawa',
        'Akwa Ibom',
        'Anambra',
        'Bauchi',
        'Bayelsa',
        'Benue',
        'Borno',
        'Cross River',
        'Delta',
        'Ebonyi',
        'Edo',
        'Ekiti',
        'Enugu',
        'Federal Capital Territory',
        'Gombe',
        'Imo',
        'Jigawa',
        'Kaduna',
        'Kano',
        'Katsina',
        'Kebbi',
        'Kogi',
        'Kwara',
        'Lagos',
        'Nasarawa',
        'Niger',
        'Ogun',
        'Ondo',
        'Osun',
        'Oyo',
        'Plateau',
        'Rivers',
        'Sokoto',
        'Taraba',
        'Yobe',
        'Zamfara',
    ];


    useEffect(() => {
        const timer = setInterval(() => {
            // Generate a random number between 1 and 3
            const randomNum = Math.floor(Math.random() * 3) + 1;
            // Set the background image based on the random number
            setBackgroundImage(`images/hero_${randomNum}.jpg`);

            // Set the display text based on the random number
            const statements = [
                "We have the best service providers for anything you need.",
                "Whether you want to clean your home, fix your furniture's, Install CCTV, Tiles, POP, windows, painting, ",
                "Unlock the full potential of your living space with our expert services.",
                // "Whether you want to clean your home, fix your furniture's, Install CCTV,\\n Tiles, POP, windows, painting, anything you want to do in your home we \\n have right professionals artisans that will handle your Job with ease"

            ];
            setDisplayText(statements[randomNum - 1]);
        }, 3000); // Change image and text every 5 seconds

        return () => clearInterval(timer); // Cleanup the timer on component unmount
    }, []);


    return (
        <div data-spy="scroll" data-target=".site-navbar-target" data-offset="300">


            <div className="site-wrap" id="home-section">

                <Nav />
                <div className="ftco-blocks-cover-1" >
                    <div className="ftco-cover-1 overlay" style={{ backgroundImage: `url(images/hero_1.jpg)`, height: "200px" }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12 text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <h1 className="">Request Our HandyMan Services</h1>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-section" >
                    <div className="site-section"  >
                        <div className="container" >
                            <div className="row align-items-stretch">
                                <div className="col-md-12">

                                    <div className="quick-contact-form bg-white">
                                        <div className="row mb-5">
                                            <div className="col-md-4 mx-auto">
                                                <h2 className="line-bottom text-center">Request for service now!</h2>
                                            </div>
                                        </div>
                                        {/* <h2>Request for service now!</h2> */}
                                        {/* <form onSubmit={handleSubmit}> */}
                                        <form onSubmit={handleSubmit} className="beautiful-form">
                                            <div className="form-group">
                                                <label htmlFor="serviceName">What service do you want?</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Service name"
                                                    id="serviceName"
                                                    name="serviceName"
                                                    value={searchTerm}
                                                    onChange={handleInputChange}
                                                />
                                                {dropdownVisible && (
                                                    <ul className="absolute bg-white left-0 right-0 mt-1 rounded-lg border border-themeWhiteLight2 z-10">
                                                        {searchResults.map((result) => (
                                                            <li
                                                                key={result._id}
                                                                className={`cursor-pointer p-2 hover:bg-gray-100  ${selectedResult && selectedResult._id === result._id ? 'bg-gray-100 border-themeBlue' : ''
                                                                    }`}
                                                                onClick={() => handleSelectResult(result)}
                                                            >
                                                                {result.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="location">Enter location, please state full address</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter location, please state full address.."
                                                    id="location"
                                                    name="location"
                                                    value={location}
                                                    onChange={(e) => setLocation(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label>Enter your name</label>
                                                <input
                                                    type="name"
                                                    id="name"
                                                    className="form-control"
                                                    placeholder="Enter your name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="state">Select State in Nigeria</label>
                                                <select
                                                    id="state"
                                                    className="form-control"
                                                    value={state}
                                                    onChange={(e) => setState(e.target.value)}
                                                    required
                                                >
                                                    <option value="">Select State</option>
                                                    {nigerianStates.map((state, index) => (
                                                        <option key={index} value={state}>
                                                            {state}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>Enter your email address</label>
                                                <input type="email" id="email" class="form-control" value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Enter your email address" required />
                                            </div>
                                            <div class="form-group">
                                                <label>Enter your phone number</label>
                                                <input
                                                    type="text"
                                                    id="phone"
                                                    className="form-control"
                                                    placeholder="Enter your phone number"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>Add service description</label>
                                                <textarea name="description" class="form-control" id="description" cols="30" value={description}
                                                    onChange={(e) => setDescription(e.target.value)} rows="5" placeholder="Add the service description here"></textarea>
                                            </div>
                                            <div class="form-group">
                                                <label>When do you want to start the Job</label>
                                                <input
                                                    type="date"
                                                    id="date"
                                                    className="form-control"
                                                    value={date}
                                                    onChange={(e) => setDate(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>Enter your minimum budget</label>
                                                <input
                                                    type="number"
                                                    id="minBudget"
                                                    className="form-control"
                                                    placeholder=''
                                                    value={minBudget}
                                                    onChange={(e) => setMinBudget(e.target.value)}
                                                required
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>Enter your maximum budget</label>
                                                <input
                                                    type="number"
                                                    id="maxBudget"
                                                    className="form-control"
                                                    value={maxBudget}
                                                    onChange={(e) => setMaxBudget(e.target.value)}
                                                    placeholder='Enter your maximum budget'
                                                required
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>How will you like to be contacted?</label>
                                                <select
                                                    id="contactMethod"
                                                    className="form-control"
                                                    value={contactMethod}
                                                    onChange={(e) => setContactMethod(e.target.value)}
                                                required
                                                >
                                                    <option value="Phone">Phone</option>
                                                    <option value="Email">Email</option>
                                                    <option value="WhatsApp">WhatsApp</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <input type="submit" value="Send Message" class="btn btn-primary px-5" />
                                            </div>
                                        </form>
                                        {successMessage && <div className="success-message" style={{ fontColor: "green", fontSize: "20" }}>{successMessage}</div>}
                                        {errorMessage && <div className="error-message" style={{ fontColor: "red" }}>{errorMessage}</div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <PostJobForm /> */}
                <footer className="site-footer footer-section mb-0" style={{ height: "" }}>
                    {/* <div className=""> */}
                    <div className="section-padd">
                        <div className="container">
                            <div className="top-footer">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="footer-logo">
                                            <a href="/">
                                                <img src="images/PNG/CGS/CGS-White.png" alt="Logo" style={{ width: "150px", height: "auto" }} />
                                            </a>
                                        </div>
                                        <div className="social-icons">
                                            <ul style={{
                                                listStyleType: "none"
                                            }}>
                                                <br />
                                                <br />
                                                <li>
                                                    <p>Plot 16 Ezekiel Uvoh Crescent, Checking Point Busstop, Ogidan, Ajah, Off Lekki - Epe Expy, Ajah 101245, Lagos</p>
                                                </li>
                                                <li>
                                                    <p>+2349038023858</p>
                                                </li>
                                                <li>
                                                    <p>info@cghomesltd.com</p>
                                                </li>
                                                <li>
                                                    <a href="javascript:;">
                                                        <i className="icon-google-plus"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;">
                                                        <i className="icon-pinterest"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="social-icons" style={{ color: 'white', display: 'inline-block' }}>
                                            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                                                <li style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    <a href="#">
                                                        <FaFacebook />
                                                    </a>
                                                </li>
                                                <li style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    <a href="#">
                                                        <FaTwitter />
                                                    </a>
                                                </li>
                                                <li style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    <a href="#">
                                                        <FaInstagram />
                                                    </a>
                                                </li>
                                                <li style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    <a href="#">
                                                        <FaGooglePlus />
                                                    </a>
                                                </li>
                                                <li style={{ display: 'inline-block' }}>
                                                    <a href="#">
                                                        <FaPinterest />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="footer-links">
                                            <ul style={{
                                                listStyleType: "none"
                                            }}>
                                                <li>
                                                    <strong className="text-primary">Customers</strong>
                                                </li>
                                                <li>
                                                    <a href="/post-jobs">Post a job</a>
                                                </li>
                                                <li>
                                                    <a href="/post-jobs">Explore Services</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="footer-links">
                                            <ul style={{
                                                listStyleType: "none"
                                            }}>
                                                <li>
                                                    <strong className="text-primary">Real Estate Service</strong>
                                                </li>
                                                <li>
                                                    <a href="https://cghomesltd.com">See the list of available properties</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="footer-links">
                                            <ul style={{
                                                listStyleType: "none"
                                            }}>
                                                <li>
                                                    <strong className="text-primary"> CG Shop</strong>
                                                </li>
                                                <li>
                                                    <a href="https://cghomesltd.com/shop/">Shop For Your Interior Item</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-copyrights">
                                <div className="section-padding-sm clearfix">
                                    <div className="float-left">
                                        <p>Copyright © 2023 CG Homes.</p>
                                    </div>
                                    <div className="float-right terms-links">
                                        <ul style={{
                                            listStyleType: "none"
                                        }}>
                                            <li>
                                                <a href="/explore#">Terms &amp; Conditions</a>
                                            </li>
                                            <li>
                                                <a href="/explore#">Privacy Policy</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </footer>
            </div>
        </div >
    )
}

export default PostJob;