import React from 'react';
import CgSection from './cghomes';

function CompanyInfo() {
  return (
    <div className="site-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-4 mr-auto">
            <h2 className="line-bottom">We have the best service providers for anything you need.
            </h2>
          </div>
          <div className="col-md-8 text-right">
            <nav className="custom-tab nav" role="tablist">
              <a href="#nav-one" className="nav-item nav-link active" data-toggle="tab" role="tab" aria-controls="nav-one" aria-selected="true">CGHOMES</a>
              {/* <a href="#nav-two" className="nav-item nav-link" data-toggle="tab" role="tab" aria-controls="nav-two" aria-selected="false">Quality</a> */}
              {/* <a href="#nav-three" className="nav-item nav-link" data-toggle="tab" role="tab" aria-controls="nav-three" aria-selected="false">Staff</a> */}
            </nav>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-one" role="tabpanel" aria-labelledby="nav-one-tab">
                <div className="row">
                  <div className="col-md-7">
                    <CgSection  style={{innerHeight: "400px"}}/>
                    {/* <img src="images/hero_1.jpg" alt="Image" className="img-fluid" /> */}
                  </div>
                  <div className="col-md-4 ml-auto">
                    <h2 className="line-bottom">Check out CGHOMES Now !</h2>
                    <p>CGHomes is here to revolutionize your home maintenance experience
                      Say Good bye to unreliable artisans and hello to a trusted company that takes responsibility for every job.
                      With CGHOMES, you’ll receive excellent value for your payment, avoiding the hassle of chasing vanishing artisans.
                      Book your appointment now and enjoy the benefits of a professional and punctual service.</p>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="nav-two" role="tabpanel" aria-labelledby="nav-two-tab">
                <div className="row">
                  <div className="col-md-7">
                    <img src="images/hero_2.jpg" alt="Image" className="img-fluid" />
                  </div>
                  <div className="col-md-4 ml-auto">
                    <h2 className="line-bottom">Quality</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Praesentium assumenda illum voluptatibus voluptas ratione error explicabo inventore obcaecati incidunt.</p>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="nav-three" role="tabpanel" aria-labelledby="nav-three-tab">
                <div className="row">
                  <div className="col-md-7">
                    <img src="images/hero_3.jpg" alt="Image" className="img-fluid" />
                  </div>
                  <div className="col-md-4 ml-auto">
                    <h2 className="line-bottom">Staff</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos quibusdam voluptatum, nesciunt nulla laudantium corporis necessitatibus explicabo nobis sapiente!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyInfo;
