import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import PostJobs from './pages/post-jobs';
import PostJob from './pages/service-demand';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/post-jobs" element={<PostJob />} />
      </Routes>
    </Router>
  );
}

export default App;
