import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const WhatWeDoSection = () => {
  const services = [
    { name: "Air conditioning (AC) Installation", icon: faCheckCircle },
    { name: "Aluminum work", icon: faCheckCircle },
    { name: "Artificial grass installation", icon: faCheckCircle },
    { name: "Borehole drilling and maintenance", icon: faCheckCircle },
    { name: "Blinds and curtains installation", icon: faCheckCircle },
    { name: "Facility management", icon: faCheckCircle },
    { name: "CCTV, DSTV, Fire Alarm, Telecom installation", icon: faCheckCircle },
    { name: "Floor interlock", icon: faCheckCircle },
    { name: "Furniture maintenance and installation", icon: faCheckCircle },
    { name: "House painting", icon: faCheckCircle },
    { name: "Tiles installation", icon: faCheckCircle },
    { name: "Pop installation", icon: faCheckCircle },
    { name: "Painting", icon: faCheckCircle },
    { name: "Solar panel installation", icon: faCheckCircle },
    { name: "Inverter installation and maintenance", icon: faCheckCircle },
    { name: "Diesel generator maintenance and installation", icon: faCheckCircle },
    { name: "Interior designing", icon: faCheckCircle },
    { name: "Construction service", icon: faCheckCircle },
    { name: "Architectural drawing", icon: faCheckCircle },
    { name: "Painting and screeding", icon: faCheckCircle },
    { name: "Swimming pool construction and maintenance", icon: faCheckCircle },
    { name: "Home renovation", icon: faCheckCircle },
    { name: "Home appliance installation", icon: faCheckCircle },
    { name: "Property management", icon: faCheckCircle }
  ];

  return (
    <div className="what-we-do-section py-5">
      <div className="container">
      <div className="row mb-5">
          <div className="col-md-4 mx-auto">
            <h2 className="line-bottom text-center">What We Do</h2>
          </div>
        </div>
        <div className="row">
          {services.map((service, index) => (
            <div key={index} className="col-md-4 mb-4">
              <div className="service-item">
                <div className="service-icon">
                  <FontAwesomeIcon icon={service.icon} />
                </div>
                <div className="service-name">{service.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatWeDoSection;
