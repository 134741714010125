import React from 'react';

function ServiceSection() {
  return (
    <div className="site-section" style={{ backgroundColor: 'grey' }}>
       <div className="row mb-5">
          <div className="col-md-4 mx-auto">
            <h2 className="line-bottom text-center">Services</h2>
          </div>
        </div>
      <div className="container">
        <div className="row align-items-stretch">
          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="service-2 h-100">
              <div className="svg">
                <img src="images/flaticon/svg/001-renovation.svg" alt="Image" className="" />
              </div>

              <h3><span>Building Services</span></h3>
              <p>Enhance your building with expert building services. We offer reliable electrical, plumbing, and HVAC solutions for a comfortable and efficient space.</p>

            </div>
          </div>
          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="service-2 h-100">
              <div className="svg">
                <img src="images/flaticon/svg/002-shovel.svg" alt="Image" className="" />
              </div>

              <h3><span>Cleaning Services</span></h3>
              <p>Impeccable cleaning services for a spotless space. Trust us for reliable and thorough cleaning solutions.</p>

            </div>
          </div>
          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="service-2 h-100">
              <div className="svg">
                <img src="images/flaticon/svg/003-bulldozer.svg" alt="Image" className="" />
              </div>

              <h3><span>Electrical</span></h3>
              <p>Expert electrical services for your space. Installation, repair, and maintenance. Reliable and efficient solutions.</p>

            </div>
          </div>


          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="service-2 h-100">
              <div className="svg">
                <img src="images/flaticon/svg/004-house-plan.svg" alt="Image" className="" />
              </div>

              <h3><span>General Repair and Maintenance</span></h3>
              <p>Trust us for all your repair and maintenance needs. We provide reliable and efficient solutions to keep your property in excellent condition.</p>

            </div>
          </div>
          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="service-2 h-100">
              <div className="svg">
                <img src="images/flaticon/svg/005-fence.svg" alt="Image" className="" />
              </div>

              <h3><span>Home Improvement Services</span></h3>
              <p>Transform your home with our professional services. From renovations to repairs, we enhance your living space with quality craftsmanship</p>

            </div>
          </div>
          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="service-2 h-100">
              <div className="svg">
                <img src="images/flaticon/svg/006-wheelbarrow.svg" alt="Image" className="" />
              </div>

              <h3><span>Logistics Services</span></h3>
              <p>Simplify logistics with our efficient services. We offer reliable transportation, warehousing, and supply chain solutions for seamless operations</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceSection
